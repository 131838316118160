import axios from 'axios.js';

export const HOME_ACTION_START = 'HOME_ACTION_START';
export const HOME_ACTION_SUCCESS = 'HOME_ACTION_SUCCESS';
export const HOME_ACTION_FAIL = 'HOME_ACTION_FAIL';
export const GET_HOME = 'GET_HOME';

const getHomeData = () => (dispatch) => {
  dispatch({ type: HOME_ACTION_START });
  axios
    .get('/projects/summary')
    .then((response) => {
      dispatch({ type: GET_HOME, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: HOME_ACTION_FAIL, payload: error });
    });
};

export { getHomeData };
