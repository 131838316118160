import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Companies = Loadable(lazy(() => import('./Companies')));
const Users = Loadable(lazy(() => import('./Users')));

const adminRoutes = [
  { path: '/admin/companies', element: <Companies /> },

  { path: '/admin/users', element: <Users /> },
];

export default adminRoutes;
