import AuthGuard from 'app/auth/AuthGuard';
import chartsRoute from 'app/views/charts/ChartsRoute';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import profileRoutes from 'app/views/profile/ProfileRoutes';
import employeesRoutes from 'app/views/users/allUsersRoutes';
import annotationRoutes from 'app/views/annotation/annotationRoutes';
import billingRoutes from 'app/views/billing/billingRoutes';
// import settingsRoutes from 'app/views/settings/settingsRoutes';
import authRoutes from './views/unauthorized/authRoute';
import adminRoutes from './views/admin/adminRoutes';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...chartsRoute,
      ...materialRoutes,
      ...profileRoutes,
      ...employeesRoutes,
      ...annotationRoutes,
      ...billingRoutes,
      // ...settingsRoutes,
      ...authRoutes,
      ...adminRoutes,
    ],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="dashboard" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
