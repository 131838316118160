import {
  GET_IMAGES,
  IMAGE_ACTION_START,
  IMAGE_ACTION_SUCCESS,
  IMAGE_ACTION_FAIL,
} from '../actions/ImageActions';

const initialState = {
  images: [],
  isLoading: false,
  error: null,
};

const ImageReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_IMAGES: {
      return {
        ...state,
        projects: action.payload,
        isLoading: false,
        error: null,
      };
    }
    case IMAGE_ACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case IMAGE_ACTION_START: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case IMAGE_ACTION_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ImageReducer;
