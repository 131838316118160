import axios from 'axios.js';

export const ADMIN_ACTION_START = 'ADMIN_ACTION_START';
export const ADMIN_ACTION_SUCCESS = 'ADMIN_ACTION_SUCCESS';
export const ADMIN_ACTION_FAIL = 'ADMIN_ACTION_FAIL';

export const updateCompany = (companyId, data) => (dispatch) => {
  dispatch({
    type: ADMIN_ACTION_START,
  });

  axios
    .put(`/admin/companies/${companyId}`, data)
    .then((res) => {
  
      dispatch({
        type: ADMIN_ACTION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
    
      dispatch({
        type: ADMIN_ACTION_FAIL,
        payload: err,
      });
    });
};

export const updateUser = (userId, data) => (dispatch) => {
  dispatch({
    type: ADMIN_ACTION_START,
  });
  axios
    .put(`/admin/users/${userId}`, data)
    .then((res) => {
      dispatch({
        type: ADMIN_ACTION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ADMIN_ACTION_FAIL,
        payload: err,
      });
    });
};
