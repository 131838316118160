import { SpaceBar } from '@mui/icons-material';
import {
  Box,
  Icon,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Typography,
} from '@mui/material';
import moment from 'moment';
import MatxLoading from './MatxLoading';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'app/hooks/useAuth';

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } },
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } },
  },
}));

const UsersTable = ({ loading, data, onEditPress }) => {
  const { company } = useAuth();

  const [showMenu, setShowMenu] = useState(false);

  return (
    <Box width="100%" overflow="auto">
      {loading && (
        <div
          style={{
            marginTop: '10px',
          }}
        >
          <MatxLoading />
        </div>
      )}

      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="center">Role</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data &&
            data.map((item, index) => (
              <TableRow key={index}>
                <TableCell align="left">
                  <Typography variant="subtitle1" gutterBottom component="p">
                    {item.firstName} {item.lastName}
                  </Typography>
                  <Typography variant="caption" display="block" component="p">
                    {item.email}
                  </Typography>
                </TableCell>
                <TableCell align="center">{item.companyRole}</TableCell>
                <TableCell align="center">{item.status}</TableCell>

                <TableCell align="right">
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      onEditPress(item);
                    }}
                    disabled={company.plan === 'free'}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>
    </Box>
  );
};

export default UsersTable;
