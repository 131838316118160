export const topBarHeight = 64;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const API_URL = 'https://api.inspect.mapthis.site/v1';
export const BASE_URL = 'https://api.inspect.mapthis.site';
// export const API_URL = 'http://localhost:3001/v1'; //
// export const BASE_URL = 'http://localhost:3001';
export const projectTypes = [
  {
    id: 1,
    name: 'Powerline',
    value: 'powerline',
  },
  {
    id: 2,
    name: 'Wind Mill',
    value: 'windmill',
  },
];

export const userRoles = [
  {
    id: 1,
    name: 'Admin',
    value: 'admin',
  },
  {
    id: 2,
    name: 'Viewer',
    value: 'viewer',
  },
  {
    id: 3,
    name: 'Editor',
    value: 'editor',
  },
];

const S3_BUCKET = 'zappyusnatek';
const REGION = 'ap-south-1';
const ACCESS_KEY_ID = 'AKIASVBZKZF2J5D35X4F';
const SECRET_ACCESS_KEY = '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';

// const S3_BUCKET = 'dronsuite';
// const REGION = 'eu-west-3';
// const ACCESS_KEY_ID = 'AKIAWRUZLZQPGWCVAY5Q';
// const SECRET_ACCESS_KEY = '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';

export { S3_BUCKET, REGION, ACCESS_KEY_ID, SECRET_ACCESS_KEY };
