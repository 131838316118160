import {
  ADMIN_ACTION_START,
  ADMIN_ACTION_SUCCESS,
  ADMIN_ACTION_FAIL,
} from '../actions/AdminActions';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const AdminReducer = function (state = initialState, action) {
  switch (action.type) {
    case ADMIN_ACTION_START: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ADMIN_ACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case ADMIN_ACTION_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default AdminReducer;
