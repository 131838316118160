import { API_URL } from 'app/utils/constant';
import axios from 'axios';
import { getErrorMsg } from 'app/utils/errors';
import { toast } from 'react-toastify';

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = API_URL;
axiosInstance.defaults.headers.common.Authorization = `Bearer ${global.localStorage.getItem(
  '@drone-app-accessToken'
)}`;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = getErrorMsg(error);
    if (message !== 'Please authenticate') {
      toast(message || 'Unknown error occurred', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
        toastId: 'custom-id-yes',
      });
    }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong!');
  }
);

export default axiosInstance;
