import {
  PROJECTS_ACTIONS_FAIL,
  PROJECTS_ACTIONS_START,
  PROJECTS_ACTIONS_SUCCESS,
  GET_ALL_PROJECTS,
  GET_SINGLE_PROJECT,
} from '../actions/ProjectActions';

const initialState = {
  projects: {},
  pageData: {},
  isLoading: false,
  error: null,
  singleProject: {},
};

const ProjectReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PROJECTS: {
      return {
        ...state,
        projects: action.payload,
        isLoading: false,
        error: null,
      };
    }
    case PROJECTS_ACTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case PROJECTS_ACTIONS_START: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case PROJECTS_ACTIONS_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case GET_SINGLE_PROJECT: {
      return {
        ...state,
        singleProject: action.payload,
        isLoading: false,
        error: null,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ProjectReducer;
