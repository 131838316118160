import {
  USER_ACTION_START,
  USER_ACTION_FAIL,
  USER_ACTION_SUCCESS,
  GET_USERS,
  GET_MY_COMPANY,
} from '../actions/UsersActions';

const initialState = {
  users: {},
  isLoading: false,
  error: null,
  company: {},
};

const UsersReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS: {
      return {
        ...state,
        users: action.payload,
        isLoading: false,
        error: null,
      };
    }
    case GET_MY_COMPANY: {
      return {
        ...state,
        company: action.payload,
        isLoading: false,
        error: null,
      };
    }

    case USER_ACTION_START: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case USER_ACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case USER_ACTION_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default UsersReducer;
