import React, { createContext, useEffect, useReducer } from 'react';
import axios from 'axios.js';
import { MatxLoading } from 'app/components';

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  tokens: null,
  company: null,
};

const setSession = (token) => {
  console.log(JSON.stringify(token));
  if (token) {
    window.localStorage.setItem('@drone-app-accessToken', token);
    // localStorage.setItem("@drone-app-refreshToken", tokens.refresh.token);
    // axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem('@drone-app-accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user, tokens, company } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        tokens,
        company,
      };
    }
    case 'LOGIN': {
      const { user, company } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        company,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        tokens: null,
        company: null,
      };
    }
    case 'REGISTER': {
      const { user, company } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user,
        company,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => {},
  logout: () => {},
  register: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password) => {
    try {
      const response = await axios.post('/auth/login', {
        email,
        password,
      });

      const { tokens, user } = response.data;

      const company = await axios.get('/company', {
        headers: {
          Authorization: `Bearer ${tokens.access.token}`,
        },
      });

      setSession(tokens.access.token);

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          company: company.data,
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const register = async (body) => {
    try {
      const response = await axios.post('/auth/register', body);

      const { tokens, user } = response.data;

      const company = await axios.get('/company', {
        headers: {
          Authorization: `Bearer ${tokens.access.token}`,
        },
      });

      setSession(tokens.access.token);

      dispatch({
        type: 'REGISTER',
        payload: {
          user,
          tokens,
          company: company.data,
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem('@drone-app-accessToken');

        // const refreshToken = window.localStorage.getItem(
        //   "@drone-app-refreshToken"
        // );

        if (accessToken) {
          setSession(accessToken);
          const response = await axios.get('/users/me', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const company = await axios.get('/company', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const { data } = response;

          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: true,
              user: data,
              company: company.data,
            },
          });
        } else {
          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: false,
              user: null,
              tokens: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INIT',
          payload: {
            isAuthenticated: false,
            user: null,
            tokens: null,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
