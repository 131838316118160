import { Card, Grid, Button } from '@mui/material';
import { Box, styled } from '@mui/system';
import useAuth from 'app/hooks/useAuth';

const CardRoot = styled(Card)(() => ({
  height: '100%',
  padding: '20px 24px',
}));

const CardTitle = styled('div')(({ subtitle }) => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize',
  marginBottom: !subtitle && '16px',
}));

const SimpleCard = ({ children, title, subtitle, icon, onPress, label, type = 'none' }) => {
  const { user, company } = useAuth();

  return (
    <CardRoot elevation={6}>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <CardTitle subtitle={subtitle}>{title}</CardTitle>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={onPress}
            disabled={(company.plan === 'free' && type !== 'none') || user.companyRole === 'viewer'}
          >
            {label}
          </Button>
        </Grid>
      </Grid>

      {subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
      {children}
    </CardRoot>
  );
};

export default SimpleCard;
