export function getErrorMsg(errorObj) {
  if (!errorObj) return '';
  if (typeof errorObj === 'string') return errorObj;
  if (errorObj.message === 'Network Error') return 'No connection';
  const { response } = errorObj;
  if (!response) return '';
  const { data } = response;
  if (!data) return '';
  const { message, error, errors } = data;
  if (message) return message;
  if (error) return error;
  if (!errors) return '';

  return '';
}
