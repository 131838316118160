import {
  GET_HOME,
  HOME_ACTION_START,
  HOME_ACTION_SUCCESS,
  HOME_ACTION_FAIL,
} from '../actions/HomeActions';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const HomeReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_HOME: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: null,
      };
    }

    case HOME_ACTION_START: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case HOME_ACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case HOME_ACTION_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default HomeReducer;
