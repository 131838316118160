import axios from 'axios.js';

export const USER_ACTION_START = 'USER_ACTION_START';
export const USER_ACTION_SUCCESS = 'USER_ACTION_SUCCESS';
export const USER_ACTION_FAIL = 'USER_ACTION_FAIL';
export const GET_USERS = 'GET_USERS';
export const GET_MY_COMPANY = 'GET_MY_COMPANY';

const getMyUsers =
  (page = 1) =>
  (dispatch) => {
    dispatch({ type: USER_ACTION_START });
    axios
      .get(`/users?page=${page}`)
      .then((response) => {
        dispatch({ type: GET_USERS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: USER_ACTION_FAIL, payload: error });
      });
  };

const createUser = (user) => (dispatch) => {
  dispatch({ type: USER_ACTION_START });
  axios
    .post('/users', user)
    .then((response) => {
      dispatch(getMyUsers());
    })
    .catch((error) => {
      dispatch({ type: USER_ACTION_FAIL, payload: error });
    });
};

const getMyCompany = () => (dispatch) => {
  dispatch({ type: USER_ACTION_START });
  axios
    .get('/company')
    .then((response) => {
      dispatch({ type: GET_MY_COMPANY, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: USER_ACTION_FAIL, payload: error });
    });
};

const updateUser = (id, user) => (dispatch) => {
  dispatch({ type: USER_ACTION_START });
  axios
    .patch(`/users/${id}`, user)
    .then((response) => {
      dispatch(getMyUsers());
    })
    .catch((error) => {
      dispatch({ type: USER_ACTION_FAIL, payload: error });
    });
};

export { getMyUsers, createUser, updateUser, getMyCompany };
