import { combineReducers } from 'redux';
import ProjectReducer from './ProjectReducer';
import ImageReducer from './ImageReducer';
import HomeReducer from './HomeReducer';
import UsersReducer from './UsersReducer';
import AdminReducer from './AdminReducer';

const RootReducer = combineReducers({
  project: ProjectReducer,
  image: ImageReducer,
  home: HomeReducer,
  users: UsersReducer,
  admin: AdminReducer,
});

export default RootReducer;
