import useSettings from 'app/hooks/useSettings';

const MatxLogo = ({ className, height = 100 }) => {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];

  return (
    <img
      src="/assets/images/logoM.png"
      className={className}
      alt="MapThis"
      height={height}
      color="#fff"
    />
  );
};

export default MatxLogo;
