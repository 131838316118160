import axios from 'axios.js';

export const IMAGE_ACTION_START = 'IMAGE_ACTION_START';
export const IMAGE_ACTION_SUCCESS = 'IMAGE_ACTION_SUCCESS';
export const IMAGE_ACTION_FAIL = 'IMAGE_ACTION_FAIL';
export const GET_IMAGES = 'GET_IMAGES';

const getImages = (projectId) => {
  return axios.get(`/images?project=${projectId}`);
};

const uploadImage = (data) => {
  return axios.post(`/images`, data);
};

export { getImages, uploadImage };
