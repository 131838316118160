import axios from 'axios.js';

export const PROJECTS_ACTIONS_START = 'GET_PROJECTS_ACTIONS_START';
export const PROJECTS_ACTIONS_SUCCESS = 'GET_PROJECTS_ACTIONS_SUCCESS';
export const PROJECTS_ACTIONS_FAIL = 'GET_PROJECTS_ACTIONS_FAIL';
export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const GET_SINGLE_PROJECT = 'GET_SINGLE_PROJECT';

export const getProjects =
  (page = 1) =>
  (dispatch) => {
    dispatch({
      type: PROJECTS_ACTIONS_START,
    });

    axios.get(`/projects?page=${page}`).then((res) => {
      dispatch({
        type: GET_ALL_PROJECTS,
        payload: res.data,
      });
    });
  };

export const createProject = (data) => (dispatch) => {
  dispatch({
    type: PROJECTS_ACTIONS_START,
  });
  axios.post(`/projects`, data).then((res) => {
    dispatch(getProjects());
  });
};

export const deleteProject = (id) => {
  return axios.delete(`/projects/${id}`);
};

export const updateProject = (projectId, data) => (dispatch) => {
  dispatch({
    type: PROJECTS_ACTIONS_START,
  });
  axios.put(`/projects/${projectId}`, data).then((res) => {
    dispatch(getProjects());
  });
};

export const getSingleProject = (projectId) => (dispatch) => {
  dispatch({
    type: PROJECTS_ACTIONS_START,
  });
  axios
    .get(`/projects/${projectId}`)
    .then((res) => {
      dispatch({
        type: GET_SINGLE_PROJECT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: PROJECTS_ACTIONS_FAIL,
        payload: err,
      });
    });
};
