import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Dashboard = Loadable(lazy(() => import('./Dashboard')));
const Projects = Loadable(lazy(() => import('./AllProjects')));
const Project = Loadable(lazy(() => import('./SingleProject')));
const ExportPDF = Loadable(lazy(() => import('./ExportPDF')));
const ExportHistory = Loadable(lazy(() => import('./ExportHistory')));

const dashboardRoutes = [
  { path: '/dashboard', element: <Dashboard />, auth: authRoles.admin },
  {
    path: '/projects',
    element: <Projects />,
  },
  {
    path: '/project/:projectId',
    element: <Project />,
  },
  {
    path: '/export-pdf/:projectId',
    element: <ExportPDF />,
  },
  {
    path: '/export-history',
    element: <ExportHistory />,
  },
];

export default dashboardRoutes;
