import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Select,
  MenuItem,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  InputAdornment,
} from '@mui/material';
import moment from 'moment';
import MatxLoading from './MatxLoading';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteProject, updateProject } from 'app/redux/actions/ProjectActions';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/DoneAll';
import RevertIcon from '@mui/icons-material/NotInterestedOutlined';
import { projectTypes } from 'app/utils/constant';
import { Delete, Search } from '@mui/icons-material';

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } },
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } },
  },
}));

const TableInner = ({ res, loading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [previous, setPrevious] = useState({});
  const [rows, setRows] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (res.data && res.data.length > 0) {
      let data = res.data.map((item) => ({ ...item, isEditMode: false }));
      if (searchTerm) {
        data = data.filter((item) => {
          const value =
            item.name.toLowerCase() +
            item.description.toLowerCase() +
            item.type.toLowerCase() +
            item.status.toLowerCase();

          return value.indexOf(searchTerm.toLowerCase()) !== -1;
        });
      }
      setRows(data);
    }
  }, [res.data, searchTerm]);

  const onToggleEditMode = (id, saving = false) => {
    setRows((state) => {
      return rows.map((row) => {
        if (row.id === id) {
          const dd = {
            name: row.name,
            description: row.description,
            status: row.status,
            type: row.type,
          };

          if (saving) {
            dispatch(updateProject(id, dd));
          }
          return { ...row, isEditMode: !row.isEditMode };
        }

        return row;
      });
    });
  };

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = (id) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };
  const onDeleteProject = () => {
    setShowDeleteDialog(false);
    deleteProject(selectedItem.id)
      .then(() => {
        res.refetch();
      })
      .catch(() => {
        res.refetch();
      });
  };

  return (
    <Box width="100%" overflow="auto">
      {(res.isLoading || loading) && (
        <div
          style={{
            marginTop: '10px',
          }}
        >
          <MatxLoading />
        </div>
      )}

      <Input
        sx={{
          width: '100%',
          border: '1px solid #e0e0e0',
          marginBottom: '10px',
          marginTop: '10px',
          height: '40px',
          padding: '0 10px',
        }}
        fullWidth
        placeholder="Search by project name, description, type, status"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        endAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
      />

      <StyledTable stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center"> Date Created </TableCell>

            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((item, index) => (
            <TableRow key={index}>
              <TableCell align="left">
                {item.isEditMode ? (
                  <Input value={item.name} name="name" onChange={(e) => onChange(e, item)} />
                ) : (
                  item.name
                )}
              </TableCell>

              <TableCell align="center">
                {item.isEditMode ? (
                  <Select
                    labelId={`selected-type-${index}`}
                    id={`selected-type-${index}`}
                    value={item.type.toLowerCase()}
                    onChange={(e) => onChange(e, item)}
                    sx={{ mb: 3 }}
                    size="small"
                    variant="outlined"
                    name="type"
                  >
                    {projectTypes.map((type, index) => (
                      <MenuItem value={type.value} key={index}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  item.type
                )}
              </TableCell>

              <TableCell align="center">
                {item.isEditMode ? (
                  <Select
                    labelId={`selected-status-${index}`}
                    id={`selected-status-${index}`}
                    value={item.status}
                    onChange={(e) => onChange(e, item)}
                    sx={{ mb: 3 }}
                    size="small"
                    variant="outlined"
                    name="status"
                  >
                    <MenuItem value="published">Published</MenuItem>
                    <MenuItem value="draft">Draft</MenuItem>
                    <MenuItem value="archived">Archived</MenuItem>
                  </Select>
                ) : (
                  item.status
                )}
              </TableCell>

              <TableCell align="center">{moment(item.dateCreated).format('DD-MM-YYYY')}</TableCell>

              <TableCell align="right">
                {item.isEditMode ? (
                  <div>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => onToggleEditMode(item.id, true)}
                    >
                      <DoneIcon />
                    </IconButton>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => onRevert(item.id)}
                    >
                      <RevertIcon />
                    </IconButton>
                  </div>
                ) : (
                  <div>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setSelectedItem(item);
                        setShowDeleteDialog(true);
                      }}
                    >
                      <Delete />
                    </IconButton>

                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => onToggleEditMode(item.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        navigate(`/project/${item.id}`);
                      }}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </div>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>

      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete project?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this project?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
          <Button onClick={onDeleteProject} autoFocus>
            YES. Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TableInner;
